import Router from 'next/router'
import ErrorLayout from 'components/layouts/error'

// const PageNotFound = (props) => {
export default function PageNotFound() {
	return (
		<div className="page page-error">
			<div className="content">
				<h1>Ошибка 404. Страница не найдена.</h1>
				<p>
					Страница не найдена.
					<br /> Но вы можете вернуться к началу.
				</p>
				<button type="button" className="button back" onClick={() => Router.push('/')}>
					На главную
				</button>
			</div>
		</div>
	)
}

PageNotFound.Layout = ErrorLayout
